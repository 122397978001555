import { render, staticRenderFns } from "./Info.vue?vue&type=template&id=e1e39104&scoped=true&"
import script from "./Info.vue?vue&type=script&lang=ts&"
export * from "./Info.vue?vue&type=script&lang=ts&"
import style0 from "./Info.vue?vue&type=style&index=0&id=e1e39104&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e1e39104",
  null
  
)

export default component.exports