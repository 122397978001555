




























































import { Component, Vue } from "vue-property-decorator";
@Component({})
export default class Header extends Vue {
	private editInfoDialog = false;
	private applyForm = {
		name: "",
		region: ""
	};
}
