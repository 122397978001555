


























































































import { Component, Vue } from "vue-property-decorator";
@Component({
	name: "Header"
})
export default class Header extends Vue {
	private dialogFormVisible = false;
	private active = 0;
	private btnMsg = "获取验证码";
	private formData = {
		newPhone: "",
		phone: "15727156273",
		password: ""
	};
	private titleText = "验证账号";

	$refs: { [key: string]: any } = {
		phoneForm: HTMLInputElement,
		passwordForm: HTMLInputElement
	};

	private onFocus(name: any, active: number) {
		this.$nextTick(() => {
			console.log(this.$refs);
			this.$refs[name].focus();
			this.active = active;
		});
	}
}
