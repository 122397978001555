





































import { Component, Vue } from "vue-property-decorator";
import editPhone from "./editPhone.vue";
import editInfo from "./editInfo.vue";
import { UserModule } from "@/store/modules/user";
@Component({
	components: {
		editPhone,
		editInfo
	}
})
export default class Header extends Vue {
	$refs: { [key: string]: any } = {
		titleText: HTMLInputElement,
		dialogFormVisible: HTMLInputElement
	};

	private openEditPhone() {
		this.$refs.editPhone.titleText = "验证账号";
		this.$refs.editPhone.dialogFormVisible = true;
	}

	private openEditInfo() {
		this.$refs.editInfo.editInfoDialog = true;
	}

	get userInfo() {
		return UserModule;
	}
}
